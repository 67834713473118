<template>
  <v-container>
    <v-layout row wrap justify-space-between class="ma-2">
      <v-spacer></v-spacer>
      <v-flex xs12 sm8 md8 lg8 class="text-right">
        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          outlined
          class="mx-1 my-1"
          small
          v-for="button in menu"
          :key="button.title"
          @click="goTo(button.route)"
        >
          <v-icon small left>{{ button.icon }}</v-icon>
          <span class="caption text-capitalize">{{ button.title }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),

  mounted() {
    this.onChangedRoute();
  },

  watch: {
    $route() {
      this.onChangedRoute();
    },
  },

  computed: {
    menu() {
      return [
        {
          icon: "fas fa-boxes",
          title: this.$t("packages"),
          route: "Packages",
          path: "",
          selected: true,
        },
        {
          icon: "fas fa-plus-circle",
          title: this.$t("add package"),
          route: "addPackages",
          path: "add-packages",
          selected: false,
        },
        {
          icon: "fas fa-key",
          title: this.$t("licenses"),
          route: "Licenses",
          path: "licenses",
          selected: false,
        },
      ];
    },
  },
  methods: {
    goTo(route) {
      this.menu.forEach((val) => {
        val.selected = false;
      });
      var i = this.menu.findIndex((x) => x.route == route);
      this.menu[i].selected = true;
      this.$router.push({ name: route });
    },

    onChangedRoute() {
      if (this.$route.name == "Packages") {
        this.menu.forEach((val) => {
          val.selected = true;
        });
        this.menu[1].selected = false;
        this.menu[2].selected = false;
      } else if (this.$route.name == "addPackages") {
        this.menu.forEach((val) => {
          val.selected = true;
        });

        this.menu[0].selected = false;
        this.menu[2].selected = false;
      } else if (this.$route.name == "Licenses") {
        this.menu.forEach((val) => {
          val.selected = true;
        });
        this.menu[0].selected = false;
        this.menu[1].selected = false;
      } else {
        this.menu.forEach((val) => {
          val.selected = true;
        });
        this.menu[1].selected = false;
        this.menu[2].selected = false;
      }
    },
  },
};
</script>

<style></style>
